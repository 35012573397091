body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes slide-in {
  from {transform: translateY(-10px);}
  to {transform: translateY(0);}
}

@keyframes slide-in-top {
  from {transform: translateY(-20px);}
  to {transform: translateY(0);}
}

@keyframes slide-in-left {
  from {transform: translateX(-2rem);}
  to {transform: translateY(0);}
}

@keyframes slide-in-right{
  from {transform: translateX(2rem);}
  to {transform: translateY(0);}
}

@keyframes zoom-in{
  from {transform: scale(0.8);}
  to {transform: scale(1);}
}

.pdf-wrapper canvas {
  width: 100% !important;
  direction: ltr;
  unicode-bidi: isolate;
  aspect-ratio: auto 1190 / 1684;
  height: unset !important;
}